/* CTA Section */

.cta-section {
  /* padding: 10rem 8rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta-section h1 {
  font-size: 4rem;
}

.cta-section p {
  font-weight: 700;
  margin-top: 2rem;
  font-size: 1.6rem;
}



@media (max-width: 600px) {
  .cta-section {
    padding: 2rem 5rem;
  }
}
