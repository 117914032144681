.event-container {
  height: auto;
  /* width: 100vw; */
  padding: 5rem 28rem 10rem 28rem;
  background-color: #f0e9dd;
}

.event-container p {
  margin-top: 8rem;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: justify;
}

/* Event Cards Section */

.event-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.upcoming-container,
.previous-container {
  padding: 10rem 8rem;
  text-align: center;
}

.upcoming-container h2,
.previous-container h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 6rem;
  text-align: center;
  margin-bottom: 10rem;
}

/* CTA Section */

.eventspage {
  background-color: #f0e9dd;

  height: 60vh;
}

/* Events Card */

.eventcard {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 2rem;
  width: 55rem;
  min-height: 30rem;
  border-radius: 1.5rem;
  text-align: left;
  margin-bottom: 4rem;
}

.event-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30rem;
}

.event-details h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.event-details p {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.event-details .info {
  font-weight: 600;
  font-size: 1.3rem;
}

.event-image {
  width: 15rem;
  background-color: grey;
  border-radius: 1rem 1rem 1rem 15rem;
}

.event-btn {
  margin-top: 5rem;
}

@media only screen and (max-width: 934px) {
  .event-container {
    padding: 1rem 3rem 1rem 3rem;
  }
}
