.expertiseDiv {
  height: 80vh;
  /* background-color: white */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.expertise-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.expertise-item {
  display: flex;
  align-items: center;
  background-color: #f5b9bb;
  border: 1px solid #324055;
  /* background-color: #f5b9bb;
    border: 1px solid #324055;
    color: #f0e9dd; */
  padding: 10px;
  margin: 10px;
  border-radius: 50px;
  transition: transform 0.2s ease-in-out;
}

.expertise-item:hover,
.expertise-item.hovered {
  transform: scale(1.1);
}

.expertise-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-color: #f2f2f2;
  border-radius: 50%;
}
.expertise-icon img {
  width: 50px;
  height: 50px;
}

.expertise-name {
  font-size: 18px;
  font-weight: bold;
  /* color: #324055; */
  color: #324055;
}

@media (max-width: 600px) {
  .expertiseDiv {
    height: 175vh;
  }
  .expertise-list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 180px);
  }
  .expertise-item {
    gap: 1rem;
    flex-direction: column;
    height: 160px;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #f5b9bb;
    border: 1px solid #324055;
    color: #f0e9dd;
  }
  .expertise-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .expertise-name {
    font-size: 12px;
    color: #324055;
  }
}
