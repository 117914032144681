/* Aditya's Navbar */
.navbar {
  height: 10rem;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem;
  background-color: #f0e9dd;
  z-index: 2;
}

.navbar .logo {
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
}

.navbar .logo img {
  height: 5rem;
}

.navbar > div {
  display: flex;
  align-items: center;
}

.navlinks {
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
}

.navlink {
  margin: 0 4.5rem 0 0;
}

.navlink:hover {
  cursor: pointer;
}

/* Aryan's Navbar */
/* nav { */
/* background-color: #333; */
/* padding: 10rem 0;
}
  
.navContainer {
    margin: 0 auto;
    display: flex;
    background-color: #F0E9DD;
}

.logo {
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 20rem;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20rem;
}

.menu a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 17rem;
    line-height: 26rem;
    color: #324055;
    text-decoration: none;
    text-align: center;
}

button{
    background-color: #324055;
    border: 0;
}

.menu > button {
    border-radius: 0.2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu > button> a{
    color: white;
} */

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10rem;
  box-shadow: 0rem 0.8rem 1.6rem 0rem rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 2rem;
  font-size: 1.5rem;
}

.dropdown-content a {
  color: black;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.hamburger-icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 5rem;
  }
  .logo > img {
    height: 4.5rem;
  }
  .navlinks {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }

  .mobile-nav-active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10rem;
    left: 0;
    background-color: #f0e9dd;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
}

.highlight {
  animation: blink 2s ease-in-out infinite, grow 2s ease-in-out infinite;
  transform-origin: center;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

@keyframes grow {
  50% {
    transform: scale(1.09);
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .navlinks {
    font-size: 1rem;
  }
}
