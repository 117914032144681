.hero-container {
  /* width: 100vw; */
  padding: 5rem 25rem 10rem 25rem;
  background-color: #f0e9dd;
}
.errorFix {
  height: 60vh;
  padding-top: 10rem;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13rem;
}

.hero .title {
  font-weight: 400;
  font-size: 5.8rem;
  line-height: 7.4rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}

.content .heading {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 6rem;
}

.content .description {
  font-weight: 500;
  font-size: 1.6rem;
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  padding: 13rem;
}

.team .heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 6rem;

  text-align: center;

  color: #324055;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 5rem;
}

.team-card {
  background: #ffffff;
  border-radius: 2rem;
  padding: 4rem 5rem;
}

.team-card img {
  /* width: 17.36vw; */
  /* height: 30.3vh; */
  border-radius: 10px;
  width: 25rem;
  height: 25rem;
}

.team-card .card-body {
  margin-top: 2.5rem;
  text-align: center;
}

.card-body .card-heading {
  font-family: "Yeseva One";
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.5rem;

  text-align: center;

  color: #324055;
}

.card-body .card-description {
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 3rem;

  text-align: center;

  color: #324055;
}

.our-mission {
  padding: 20rem 25rem;
  background: #dedede;
}

@media (max-width: 800px) {
  .cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 668px) {
  .hero-container,
  .our-mission,
  .team {
    padding: 5rem 10rem 20rem 10rem;
  }

  .cards-container {
    grid-template-columns: 1fr;
  }
  .errorFix {
    height: 60vh;
    padding-top: 0rem;
  }
}

@media (max-width: 460px) {
  .hero-container,
  .our-mission,
  .team {
    padding: 5rem 5rem 20rem 5rem;
  }
}
