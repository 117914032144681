.subscribe-card {
  display: flex;
  align-items: center;
  height: 70rem;
  width: 100%;
  background-color: #f0e9dd;
  padding: 2rem 10rem 10rem 10rem;
}

.subscribe-card-left > p {
  font-family: poppins;
  font-size: 1.6rem;
  text-align: left;
  padding-top: 2rem;
  font-weight: 400;
}

.subscribe-card-left {
  width: 50%;
  text-align: left;
}

.subscribe-card-left h1 {
  text-align: left;
  font-size: 3rem;
}

.subscribe-card-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.subscribe-card-right > img {
  height: 38rem;
  border-radius: 0.5rem;
}

.subscribe-card-form {
  height: 5rem;
  display: flex;
  /* justify-content: center; */
}

.subscribe-card-form > form > input {
  padding: 1.5rem;
  width: 17rem;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
}
.subscribe-card-form > form {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* gap: 1rem; */
  align-items: center;
}

.subscribe-card-form > form > button {
  background-color: #324055;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}
.subscribe-card-form > p {
  padding-top: 2rem;
  font-size: 2rem;
}

.warning {
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

/* .subscribe-card {
  display: flex;
  align-items: center;
  height: 60rem;
  background-color: #f0e9dd;
}

h3 {
  font-family: poppins;
  font-size: 18rem;
}

.subscribe-card-left {
  width: 50%;
  text-align: center;
}

.subscribe-card-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.subscribe-card-right > img {
  height: 500rem;
  border-radius: 0.5rem;
}

.subscribe-card-form {
  height: 50rem;
  display: flex;
  justify-content: center;
  /* align-items: center;
  gap: 1rem; 
}*/

.subscribe-card-form > form > input {
  padding: 1.5rem;
  width: 17rem;
  border-radius: 0.5rem;
  border: none;
  margin-bottom: 1rem;
}
.subscribe-card-form > form {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  gap: 1rem;
  /* justify-content: space-evenly; */
  align-items: center;
}

.subscribe-card-form > form > button {
  background-color: #324055;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}
.subscribe-card-form > p {
  padding-top: 2rem;
  font-size: 2rem;
}

.warning {
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
/* @media only screen and (max-width: 600px) {
  .subscribe-card {
    padding-top: 0.5rem;
    flex-direction: column;
    gap: 13rem;
    height: 120vh;
  }
  h1 {
    font-size: 3rem;
    width: 100%;
    padding-bottom: 1rem;
  }
  h3 {
    width: 100%;
    padding-bottom: 2rem;
  }
  .subscribe-card-right > img {
    height: 20rem;
    border-radius: 0.5rem;
  }
} */

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .subscribe-card {
    height: 50rem;
    flex-direction: column;
    gap: 10rem;
    padding: 2rem;
  }
  h1 {
    font-size: 2.4rem;
    width: 100%;
    padding-bottom: 1rem;
  }
  h3 {
    width: 100%;
    padding-bottom: 2rem;
  }
  .subscribe-card-right > img {
    height: 30rem;
    border-radius: 0.5rem;
  }
  .subscribe-card-form > form > input {
    width: 14rem;
  }
}
@media only screen and (max-width: 600px) {
  .subscribe-card {
    padding-top: 5rem;
    flex-direction: column;
    gap: 13rem;
    height: 100vh;
    padding: 2rem;
  }
  h1 {
    font-size: 3rem;
    width: 100%;
    padding: 2.5rem;
    line-height: 28px;
    /* padding-bottom: 1rem; */
  }
  h3 {
    width: 100%;
    padding-bottom: 2rem;
  }
  .subscribe-card-left {
    width: auto;
  }
  .subscribe-card-right > img {
    height: 25rem;
    border-radius: 0.5rem;
  }
  .subscribe-card {
    padding: 6rem;
  }
  .subscribe-card-left > p {
    font-size: 2rem;
    padding: 2.5rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .subscribe-card {
    height: 60vh;
  }
  h1 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  .subscribe-card-left {
    width: 100%;
    padding: 2rem 10rem 2rem 10rem;
  }
  .subscribe-card-right > img {
    height: 30rem;
  }
}

#reveal-text span {
  display: inline-block;
  animation: reveal-word 3s ease-in-out forwards;
}

#reveal-text {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  animation: reveal-text 3s ease-in-out forwards;
}

@keyframes reveal-text {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 100px;
    opacity: 1;
  }
}

@keyframes reveal-word {
  0% {
    opacity: 0;
  }
  8.33% {
    opacity: 1;
  }
  16.66% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  33.33% {
    opacity: 0;
  }
  41.66% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  58.33% {
    opacity: 1;
  }
  66.66% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  83.33% {
    opacity: 0;
  }
  91.66% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
