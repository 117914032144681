
.info-container {
  display: flex;
  flex-direction: column;
  gap: 7rem;

  padding-bottom: 10rem;
}

.profile-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7rem;
}

.profile-img {
  width: 20rem;
  height: 20rem;
}

.profile-info:nth-child(even) .profile-img {
  order: 2;
}

.profile-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.5rem;

  color: #324055;
  margin-bottom: 3rem;
}

.profile-description {
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
  font-size: 1.6rem;
  line-height: 3rem;

  color: #324055;
}

.book-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 5rem;

  padding: 20rem 28rem;
  background: #f0e9dd;
}

.book-call .content {
  text-align: center;
  gap: 2.4rem;
}

.book-call .heading {
  font-family: "Yeseva One";
  font-style: normal;
  font-weight: 400;
  font-size: 4.2rem;
  line-height: 4rem;

  color: #324055;
}

.book-call .subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  color: #324055;
}

.handleButton{
  display: flex;
  justify-content: center;
}

.book-call-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.6rem;
  padding: 1.5rem 2rem;
  text-align: center;
  color: #f9f7f2;
  background: #324055;
  border-radius: 1rem;
}

@media (max-width: 640px) {
  .one-on-one .hero-container {
    padding: 5rem 5rem 20rem 5rem;
  }

  .team {
    padding: 5rem 7rem 2rem 7rem;
  }

  .profile-description {
    text-align: left;
  }

  .profile-info {
    flex-direction: column;
  }

  .profile-info:nth-child(even) .profile-img {
    order: 0;
  }

  .book-call {
    padding: 20rem 5rem;
  }
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-post {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
  border: 1px solid black;
  padding: 1rem;
}

.price-post img {
  width: 200px;
  height: 300px;
  object-fit: cover;
  margin-right: 1rem;
}

.price-post h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.price-post p {
  margin: 0;
}

