footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #324055;
  color: #fff;
  padding: 8rem 10rem;
  overflow: hidden;
}

footer > div {
  display: flex;
  flex-wrap: wrap;
}

.footerlogo img {
  height: 4rem;
  margin: 0 20rem 8rem 0;
  cursor: pointer;
}

.footer-group {
  margin: 0 10rem 8rem 0;
}

.footer-group h4 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.footer-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 400;
}

.footer-group li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footer-group a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.copyright {
  flex-basis: 100%;
  text-align: center;
}

.footer-icon {
  height: 1.5rem;
  margin-right: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footerSection {
    flex-basis: 100%;
  }

  .footerLogo {
    flex-basis: 100%;
    margin-right: 0;
  }

  .footerlogo img {
    height: 5rem;
  }

  .footer-group h4 {
    font-size: 2.5rem;
  }

  .footer-group ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
  }
}
@media (max-width: 375px) {
  .footerSection {
    flex-basis: 100%;
    text-align: center;
    width: 100%;
  }
  /* .footerSection h4 {
        display: none;
    } */

  .footerSection li {
    margin-bottom: 0.25rem;
  }

  .footerLogo {
    margin: 1rem 0;
  }

  .copyright {
    margin-top: 1rem;
  }
}
