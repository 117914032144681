.blogpost {
  padding: 5rem 10rem;
  overflow-x: hidden;
}

.blogpost .thumbnail {
  width: 70%;
  height: 50rem;
  border-radius: 1.5rem;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  margin: 5rem 0;
}

.blogpost h1 {
  text-align: left;
  font-family: "Poppins", "sans-serif";
  font-weight: 700;
  font-size: 3rem;
  margin: 3rem 0;
}

.blogpost p {
  background-color: transparent !important;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  line-height: normal !important;
  margin: 1rem 0 !important;
}

.content-container img {
  border-radius: 10px;
}

.content-container img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  border: 0rem;
  margin-bottom: 0rem;
  display: block;
  transition: none !important;
}

.content-container strong {
  display: block;
}

.content-container h2,
.content-container strong {
  font-weight: 700 !important;
  font-size: 1.8rem;
  box-sizing: initial !important;
  margin: 5rem 0 !important;
}

.blogpost .line {
  height: 0.3rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
}

.blogpost .like-button {
  display: flex;
  align-items: center;
  background-color: #f0e9dd;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  width: fit-content;
}

.blogpost .like-button:hover {
  cursor: pointer;
}

.blogpost .like-button img {
  height: 1.8rem;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .blogpost {
    padding: 5rem;
  }

  .blogpost .thumbnail {
    width: 100%;
    height: 50rem;
  }

  .blogpost h1 {
    font-size: 3.5rem;
  }

  .blogpost p {
    font-size: 2.2rem !important;
  }

  .content-container img {
    /* max-width: 70%; */
    max-width: 50%;
  }

  .content-container h2,
  .content-container strong {
    font-size: 2.6rem;
  }
}
