.create-blog {
  margin: 5rem 10rem;
}

.create-blog-heading {
  font-weight: 400;
  font-size: 5.8rem;
  line-height: 7.4rem;

  color: #324055;
}

.blog-content {
  height: 15rem;
}

.create-blog form {
  width: 100%;
  text-align: left;
}

.create-blog label {
  font-weight: 700;
  font-size: 1.6rem;
  color: #324055;
  margin-bottom: 1rem;
}

.create-blog input {
  margin-bottom: 2rem;
  box-sizing: border-box;

  border: 0.2rem solid rgba(0, 0, 0, 0.3);
  border-radius: 1rem;

  /* width: 500rem; */
  height: 5rem;

  background-color: transparent;
  padding: 0 3.6rem;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;

  color: #324055;
}

.jodit-wysiwyg {
  height: 50rem !important;
}

.submit-btn {
  margin-top: 2.5rem;
  width: 100%;
  transition: 0.2s;
}

.submit-btn:hover {
  background-color: rgb(60, 88, 131);
}
