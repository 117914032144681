.container {
  display: flex;
  align-items: center;
  /* justify-content: center;   [Aditya] Removed this line because I am using 'container' don't want content to be centered. */
  flex-direction: column;
  height: 100vh;
  /* width: 100vw; */
  background: #f0e9dd;
}

.signup-container {
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 50px 0;
}

.form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 500px;
  height: 98px;
}

.form-item label {
  font-weight: 700;
  font-size: 16px;
  color: #324055;
}

.form-item input {
  box-sizing: border-box;

  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;

  width: 500px;
  height: 50px;

  background-color: transparent;
  padding: 0 36px;

  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  color: #324055;
}

.form-btn {
  box-sizing: border-box;
  background: #324055;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;

  height: 50px;

  text-align: center;

  color: #ffffff;
}

form p {
  text-align: end;
  margin: -10px 0 10px 0;
}

.form-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 23px;

  margin: 45px 0;
}

.dividing-line {
  width: 150px;
  height: 1px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.divider-txt {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  text-align: center;

  color: #324055;
}

.google-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;

  text-align: center;

  color: #324055;
}

.google-logo {
  margin-right: 20px;
}

.dontHaveAccount {
  text-align: start;
  width: 500px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #324055;
}

@media (max-width: 600px) {
  .heading {
    font-size: 32px;
  }

  form {
    width: auto;
  }

  .form-item label {
    font-size: 14px;
  }

  .form-item,
  .form-item input,
  .form-btn,
  .form-divider,
  .dontHaveAccount {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
  }

  .dividing-line {
    width: 50px;
  }
}
