.not-found-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* overflow-x: hidden; */
}

.not-found-img {
  max-height: 70vh;
}

.warning {
  color: #fd779b;
  font-family: poppins;
  font-weight: 700;
  font-size: 3.4rem;
}

.go-back-button {
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  color: white;
  background-color: #fd779b;
  font-family: poppins;
  font-weight: 700;
  font-size: 1.6rem;
}
