.videoMain {
  padding: 15rem;
  background-color: #f0e9dd;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.videoMain > p {
  text-align: center;
  font-weight: 700;
  margin-top: 5rem;
  padding: 0 12rem 8rem 12rem;
  font-size: 1.5rem;
}

.videoMain h1 {
  font-size: 4rem;
}



/* Featured Section */

.feature-section {
  background-color: #dee7e7;
  padding: 10rem 8rem;
  text-align: center;
}
.feature-section > h2,
.recent-articles-section > p {
  padding-bottom: 2rem;
  font-size: 2.5rem;
}

.feature-container {
  display: flex;
}

.feature-image {
  height: 35rem;
  width: 50vw;
  border-radius: 1.5rem;
  background-size: cover;
}

.featureContent {
  width: 50vw;
  margin-left: 5rem;
  text-align: left;
}

.featureContent h4 {
  font-size: 2rem;
  margin: 2rem 0;
}

.featureContent p {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 500;
  margin-bottom: 3rem;
}

.category-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: fit-content;
  padding: 0 3rem;
  border-radius: 4rem;
  background-color: #f0c4c4;
  font-weight: 600;
  font-size: 1.3rem;
}

/* Other Videos Section */

.other-videos-section {
  padding: 10rem 5rem;
}

.other-videos-section .videos-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .videoMain {
    padding: 15rem 5rem 15rem 5rem;
  }

  .videoMain p {
    padding: 1rem;
    width: 100%;
    margin-bottom: 50px;
    font-size: 2.2rem;
  }

  .feature-section {
    padding: 10rem 5rem 10rem 5rem;
  }

  .feature-section h2 {
    margin-bottom: 30px;
    font-size: 3.5rem;
  }

  .feature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .featureContent {
    width: 100%;
    margin-left: 0;
    /* padding: 4rem; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .featureContent > h4 {
    text-align: center;
    font-size: 2.6rem;
    margin: 30px 0;
  }
  .featureContent > p {
    text-align: center;
    font-size: 2.2rem;
  }

  .feature-image {
    width: 25rem;
    height: 25rem;
    margin-bottom: 20px;
  }

  .feature-container .videoBrowse-btn {
    margin: 20px 0 30px;
  }

  .other-videos-section .videos-container {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
  }
}
