.blog-container h1 {
  margin-top: 10rem;
  width: 40rem;
}

.blog-container p {
  font-weight: 700;
  margin-top: 2rem;
}

.feature-section h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 6rem;
  text-align: center;
  color: #324055;
}
/* Second Section */

.blogpage h2 {
  font-family: "Yeseva One";
  margin-bottom: 6rem;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 6rem;
  text-align: center;
  color: #324055;
}

.blogpage .category-container,
.recent-articles-section {
  text-align: center;
  padding: 10rem 8rem;
}

.blogpage .carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blogpage .carousel {
  display: flex;
}

.blogpage .arrow {
  height: 3rem;
  opacity: 0.3;
}

.blogpage .arrow:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* Featured Section */

.feature-section {
  background-color: #dee7e7;
  padding: 10rem 8rem;
  text-align: center;
}

.feature-container {
  display: flex;
}

.feature-image {
  height: 35rem;
  width: 50vw;
  border-radius: 1.5rem;
  background-size: cover;
}

.feature-content {
  width: 50vw;
  margin-left: 5rem;
  text-align: left;
}

.feature-content h4 {
  font-size: 2rem;
  margin: 2rem 0;
}

.feature-content p {
  text-align: left;
  font-weight: 500;
  margin-bottom: 3rem;
}

.category-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: fit-content;
  padding: 0 3rem;
  border-radius: 4rem;
  background-color: #f0c4c4;
  font-weight: 600;
  font-size: 1.3rem;
}

/* Recent Articles Section */

.recent-articles-section .articles-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.videoMain {
  padding: 15rem;
  background-color: #f0e9dd;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.videoMain > p {
  text-align: center;
  font-weight: 700;
  margin-top: 5rem;
  padding: 0 12rem 8rem 12rem;
  font-size: 1.5rem;
}

/* Featured Section */

.feature-section {
  background-color: #dee7e7;
  padding: 10rem 8rem;
  text-align: center;
}
.feature-section > h2,
.recent-articles-section > p {
  padding-bottom: 2rem;
  font-size: 2.5rem;
}

.feature-container {
  display: flex;
}

.feature-image {
  height: 35rem;
  width: 50vw;
  border-radius: 1.5rem;
  background-size: cover;
}

.featureContent {
  width: 50vw;
  margin-left: 5rem;
  text-align: left;
}

.featureContent h4 {
  font-size: 2rem;
  margin: 2rem 0;
}

.featureContent p {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 500;
  margin-bottom: 3rem;
}

.category-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: fit-content;
  padding: 0 3rem;
  border-radius: 4rem;
  background-color: #f0c4c4;
  font-weight: 600;
  font-size: 1.3rem;
}

/* Recent Articles Section */

.recent-articles-section .articles-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* @media screen and (max-width: 600rem) {
  .articles-section{
      padding: 0;
      width: 100%
  }
} */
