.email-input > form,
.email-input > div {
  display: flex;
  margin-top: 4rem;
}

.email-input input {
  height: 6rem;
}

.email-container .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
}

.email-input input {
  width: 40rem;
  border: 0.2rem solid rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  background-color: transparent;
  font-size: 1.6rem;
  padding: 0 2rem;
}

.email-input .btn {
  font-size: 1.4rem;
  padding: 1.5rem 0;
  width: 12rem;
  margin-left: 0.5rem;
  font-family: poppins;
}

.email-input .note {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  padding-top: 1.5rem;
}

@media (max-width: 484px) {
  .email-input > form,
.email-input > div {
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
}
