.parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60rem;
    background-color: white;
    /* background-color: #F9F7F2; */
  }
  
  .questionnaire {
    display: grid;
    grid-template-columns: repeat(2, 80vh);
    justify-content: center;
  }
  
  .questionnaire>label {
    display: flex;
    align-items: center;
    margin: 10px;
    font-size: 1.5rem;
    font-family: 'poppins';
    font-weight: 500;
    background-color: #f0e9dd;
    border-radius: 5px;
    padding: 2rem;
    color: #324055;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
 

  @media only screen and (max-width: 600px) {
    .parent {
        display: flex;
        height: 130vh;
    }
    .questionnaire {
        grid-template-columns: repeat(1, 90vw);
        
      }
  }
  
