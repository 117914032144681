.pages {
  padding: 10rem;
}

.pages-heading {
  margin-bottom: 5rem;
}

.pages-heading .title {
  font-family: "Yeseva One";
  font-style: normal;
  font-weight: 400;
  font-size: 6.4rem;
  line-height: 7.4rem;
  text-align: start;

  max-width: 60rem;

  color: #000000;
}

.pages-heading .subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 4.8rem;

  margin-top: 0.8rem;

  color: #000000;
}

.content {
  padding: 2rem;
}

.content ol {
  margin: 1rem 5rem;
}

.heading-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 4.8rem;

  margin: 2rem 0;

  color: #000000;
}

.content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3.3rem;

  color: #000000;
}

h3 {
  font-size: 1.8rem;
}

@media (max-width: 424px) {
  .pages {
    padding: 10rem 7rem;
  }
}
