.box {
  width: 100%;
  padding: 15rem;
  background-color: white;
  margin: 0 auto;
  text-align: center;
}
.illustrationBox h2 {
  font-family: "Yeseva One";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  padding-bottom: 10px;
}

.illustrationBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  background-color: #f9f7f3;
  border-radius: 15px;
  width: 100%;
  height: 40rem;
  background-image: url("../../../assets/ooo-bg.svg");
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: center 10rem;
}

.illustrationBox > form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.emailContainer,
.email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 16rem; */
  gap: 1rem;
  padding-bottom: 0px;
}
.ooob {
  width: 100%;
  background-color: #324055;
  color: white;
  font-weight: 700;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .illustrationBox {
    height: 50rem;
    background-position: center 25rem;
  }
}

@media only screen and (max-width: 600px) {
  .box {
    padding: 5rem;
  }

  .illustrationBox {
    height: 80rem;
    background-size: 90%;
    background-position: center 30rem;
    background-image: url("../../../assets/ooo-bg-2.svg");
    background-size: contain;
    /* background-position: center;
    background-position-y: bottom; */
    justify-content: space-between;
  }
}
